<template>
  <container>
    <v-card outlined>
      <v-card-title>
        {{ $capitalize($tc("model.letter_page_title")) }}
      </v-card-title>
      <v-card-text class="d-flex justify-space-between">
        <div>
          <v-btn
            v-if="$store.getters['auth/getScope']('letters.add')"
            color="primary"
            small
            class="mr-2 mb-2"
            @click="openCreateLetterDialog"
            >{{ $tc("model.letter_create_button") }}</v-btn
          >
          <v-btn
            v-if="$store.getters['auth/getScope']('letters.print')"
            color="primary"
            small
            outlined
            class="mb-2"
            @click="handlePrintMany"
            :loading="printing_many"
            :disabled="selected_letters.length == 0"
            >{{ $tc("model.letter_print_many_button") }}</v-btn
          >
        </div>
        <div>
          <v-btn @click="fetchLetters()" icon :loading="fetching">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-menu
            v-model="opened_advanced_filter"
            :close-on-content-click="false"
            offset-x
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-badge :value="is_filtered" color="red" dot overlap>
                <v-btn v-bind="attrs" v-on="on" icon small>
                  <v-icon>mdi-filter-menu-outline</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-form @submit.prevent="handleAdvancedFilter()">
              <v-card width="500px">
                <v-card-title>
                  {{ $capitalize($tc("model.letter_advanced_filter_title")) }}
                </v-card-title>
                <v-card-text>
                  <v-list>
                    <v-list-item>
                      <v-radio-group
                        v-model="advanced_filter.sent"
                        :label="$capitalize($tc('model.letter_send_button'))"
                        mandatory
                      >
                        <v-radio
                          value="sent"
                          :label="
                            $capitalize($tc('model.letter_sent_check_box'))
                          "
                        ></v-radio>
                        <v-radio
                          value="not_sent"
                          :label="
                            $capitalize($tc('model.letter_unsent_check_box'))
                          "
                        ></v-radio>
                        <v-radio
                          value="all"
                          :label="
                            $capitalize($tc('model.letter_all_of_the_above'))
                          "
                        ></v-radio>
                      </v-radio-group>
                    </v-list-item>
                    <v-list-item>
                      <v-select
                        v-model="advanced_filter.letter_type_id"
                        :items="letter_types"
                        :label="
                          $capitalize($tc('model.letter_form_type_label'))
                        "
                        item-text="name"
                        item-value="id"
                        clearable
                      />
                    </v-list-item>
                    <v-list-item>
                      <v-autocomplete
                        v-model="advanced_filter.lead"
                        :search-input.sync="search_lead_for_filtering"
                        :items="leads_for_filtering"
                        item-text="identifier_code"
                        return-object
                        :label="
                          $capitalize($tc('model.letter_form_lead_label'))
                        "
                        clearable
                      ></v-autocomplete>
                    </v-list-item>
                    <v-list-item>
                      <v-autocomplete
                        name="letter.recipient_id"
                        v-model="advanced_filter.recipient_id"
                        :items="
                          advanced_filter.lead
                            ? advanced_filter.lead.participants || []
                            : []
                        "
                        no-filter
                        item-text="contact.name"
                        item-value="contact_id"
                        :label="
                          $capitalize($tc('model.letter_form_recipient_label'))
                        "
                        clearable
                      >
                        <template v-slot:selection="{ item }">
                          {{
                            `${item.contact.name} (${item.type.description})`
                          }}
                        </template>
                        <template v-slot:item="{ item }">
                          {{
                            `${item.contact.name} (${item.type.description})`
                          }}
                        </template>
                      </v-autocomplete>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="handleResetFilter" text>{{
                    $capitalize(
                      $tc("model.letter_advanced_filter_button_clean")
                    )
                  }}</v-btn>
                  <v-btn type="submit" color="primary">{{
                    $capitalize(
                      $tc("model.letter_advanced_filter_buttons_search")
                    )
                  }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-menu>
        </div>
      </v-card-text>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="letters"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="fetching"
          :disabled="fetching"
        >
          <template v-slot:item.selected="{ item }">
            <v-checkbox
              v-model="selected_letters"
              :value="item.id"
            ></v-checkbox>
          </template>
          <template v-slot:item.sender_id="{ item }">
            <user-avatar
              :avatar_url="item.sender ? item.sender.avatar_url : null"
              :user_name="item.sender ? item.sender.name : 'Sistema'"
              :user_id="item.sender ? item.sender.id : null"
            />
          </template>
          <template v-slot:item.lead_number="{ item }">
            <v-chip
              v-if="item.lead_number"
              :href="generatedLeadLink(item.lead_number)"
              target="_blank"
              link
            >
              <v-icon small left>mdi-open-in-new</v-icon>
              {{ item.lead_number }}
            </v-chip>
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ formatDate(item.created_at) }}
          </template>
          <template v-slot:item.sent_at="{ item }">
            {{ formatDate(item.sent_at) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-on="on" v-bind="attrs">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <ScopeProvider scope="letters.print">
                  <v-list-item :href="item.document_url" target="_blank">
                    <v-icon left size="20">mdi-file-find</v-icon>
                    <v-list-item-title>{{
                      $capitalize($tc("model.letter_print_button"))
                    }}</v-list-item-title>
                  </v-list-item>
                </ScopeProvider>
                <ScopeProvider scope="letters.send">
                  <v-list-item @click="confirmSend(item.id)">
                    <v-icon left size="20">mdi-send</v-icon>
                    <v-list-item-title>{{
                      $capitalize($tc("model.letter_mark_as_sent_button"))
                    }}</v-list-item-title>
                  </v-list-item>
                </ScopeProvider>
                <ScopeProvider scope="letters.delete">
                  <v-list-item value="true" @click="confirmDelete(item.id)">
                    <v-icon left size="20">mdi-delete</v-icon>
                    <v-list-item-title>{{
                      $capitalize($tc("model.letter_remove_button"))
                    }}</v-list-item-title>
                  </v-list-item>
                </ScopeProvider>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <confirm-action-dialog
      :confirm_action="send_dialog"
      :message="$tc('model.letter_confirm_send_message')"
      @change="send_dialog = $event"
      @action="handleSend"
    />
    <confirm-action-dialog
      :confirm_action="delete_dialog"
      :message="$tc('model.letter_confirm_delete_message')"
      @change="delete_dialog = $event"
      @action="handleDelete"
    />
    <v-dialog v-model="create_dialog" persistent max-width="700px">
      <v-card>
        <v-form @submit.prevent="handleNewLetter">
          <v-card-title>
            {{ $tc("model.letter_create_letter_dialog_title") }}
            <v-spacer />
            <v-btn icon @click="closeCreateLetterDialog"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text>
            <ValidationObserver ref="form_new_letter">
              <ValidationProvider
                name="letter.letter_type_id"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  name="letter.letter_type_id"
                  v-model="form_new_letter.letter_type_id"
                  :items="letter_types"
                  :label="$capitalize($tc('model.letter_form_type_label'))"
                  item-text="name"
                  item-value="id"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <ValidationProvider name="letter.lead_id" v-slot="{ errors }">
                <v-autocomplete
                  name="letter.lead_id"
                  v-model="form_new_letter.lead"
                  :search-input.sync="search_lead_for_creation"
                  :items="leads_for_creation"
                  :label="$capitalize($tc('model.letter_form_lead_label'))"
                  :error-messages="errors"
                  item-text="identifier_code"
                  return-object
                />
              </ValidationProvider>
              <ValidationProvider
                name="letter.recipient_id"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="form_new_letter.recipient_id"
                  :items="
                    form_new_letter.lead
                      ? form_new_letter.lead.participants || []
                      : []
                  "
                  :label="$capitalize($tc('model.letter_form_recipient_label'))"
                  :placeholder="
                    $capitalize($tc('model.letter_search_contacts_placeholder'))
                  "
                  item-text="contact.name"
                  item-value="contact_id"
                  :error-messages="errors"
                >
                  <template v-slot:selection="{ item }">
                    {{ `${item.contact.name} (${item.type.description})` }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ `${item.contact.name} (${item.type.description})` }}
                  </template>
                </v-select>
              </ValidationProvider>
            </ValidationObserver>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" type="submit" :loading="submitting">
              {{ $capitalize($tc("model.interface_save_button")) }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </container>
</template>

<script>
import { mapActions } from "vuex";
import { formatDate } from "@/tools/date";
import {
  index as indexLetters,
  destroy as destroyLetter,
  send as sendLetter,
  store as storeLetter,
  printMany as printManyLetters,
} from "@/services/letters";
import { indexLeadsWithParticipantsNames } from "@/services/lead";
import UserAvatar from "@/components/UserAvatar";
import ConfirmActionDialog from "@/components/ConfirmActionDialog";
import ScopeProvider from "@/components/ScopeProvider";

export default {
  components: {
    UserAvatar,
    ScopeProvider,
    ConfirmActionDialog,
  },
  data: function () {
    return {
      // Confirmation Action
      delete_dialog: false,
      delete_letter_id: null,
      send_dialog: false,
      send_letter_id: null,

      // New Letter Dialog
      create_dialog: false,
      submitting: false,
      form_new_letter: {
        letter_type_id: null,
        recipient_id: null,
        lead: {
          participants: [],
        },
      },

      // Main
      fetching: false,
      printing_many: false,
      headers: [
        {
          text: "",
          value: "selected",
          sortable: false,
        },
        {
          text: this.$capitalize(this.$tc("model.letter_header_type")),
          value: "type.name",
          sortable: false,
        },
        {
          text: this.$capitalize(this.$tc("model.letter_header_recipient")),
          value: "recipient_name",
        },
        {
          text: this.$capitalize(this.$tc("model.letter_header_lead")),
          value: "lead_number",
          width: 150,
        },
        {
          text: this.$capitalize(this.$tc("model.letter_header_created_by")),
          value: "sender_id",
          width: 120,
        },
        {
          text: this.$capitalize(this.$tc("model.letter_header_created_at")),
          value: "created_at",
        },
        {
          text: this.$capitalize(this.$tc("model.letter_header_sent_at")),
          value: "sent_at",
        },
        {
          text: this.$capitalize(this.$tc("model.letter_header_actions")),
          value: "actions",
          sortable: false,
          width: 20,
        },
      ],
      totalItems: 0,
      options: {
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      letters: [],
      selected_letters: [],

      leads_for_creation: [],
      search_lead_for_creation: "",
      fetching_leads_for_creation: false,

      leads_for_filtering: [],
      search_lead_for_filtering: "",
      fetching_leads_for_filtering: false,

      //Advanced Filter
      opened_advanced_filter: false,
      advanced_filter: {
        sent: "all",
        letter_type_id: null,
        recipient_id: null,
        lead: {
          participants: [],
        },
      },
      advanced_search_fetchings_contacts: false,
      advanced_search_contacts: null,
      advanced_search_contacts_list: [],
    };
  },
  methods: {
    // New Letter Dialog
    openCreateLetterDialog() {
      this.create_dialog = true;
    },
    closeCreateLetterDialog() {
      this.create_dialog = false;
      this.form_new_letter = {
        letter_type_id: null,
        recipient_id: null,
        lead_id: null,
        lead: {
          participants: [],
        },
      };
      this.$refs.form_new_letter.reset();
    },

    // Confirmation Action
    confirmSend(letter_id) {
      this.send_dialog = true;
      this.send_letter_id = letter_id;
    },
    confirmDelete(letter_id) {
      this.delete_dialog = true;
      this.delete_letter_id = letter_id;
    },

    // Actions
    async handleNewLetter() {
      const validation = await this.$refs.form_new_letter.validate();
      if (!validation) return;

      this.submitting = true;
      try {
        const response = await storeLetter({
          letter: {
            ...this.form_new_letter,
            lead_id: this.form_new_letter.lead?.id,
          },
        });
        this.fetchLetters();
        this.closeCreateLetterDialog();
      } catch (error) {
        if (error?.response?.data?.errors) {
          this.$refs.form_new_letter.setErrors(error.response.data.errors);
        }

        if (error?.response?.data?.message) {
          this.$store.commit("sendMessage", {
            text: error.response.data.message,
            color: "red",
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    async handlePrintMany() {
      this.printing_many = true;
      try {
        const response = await printManyLetters(this.selected_letters);
        const blob = new Blob([response], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.$tc("model.letter_print_many_error_message"),
          color: "red",
        });
      } finally {
        this.printing_many = false;
      }
    },
    async handleSend() {
      try {
        await sendLetter(this.send_letter_id);
        this.fetchLetters();
        this.$store.commit("sendMessage", {
          text: this.$tc("model.letter_send_success_message"),
        });
      } catch (error) {
        if (error.response.status === 400) {
          this.$store.commit("sendMessage", {
            text: error.response.data.message,
            color: "red",
          });
          return;
        }

        this.$store.commit("sendMessage", {
          text: this.$tc("model.letter_send_error_message"),
          color: "red",
        });
      } finally {
        this.send_dialog = false;
        this.send_letter_id = null;
      }
    },
    async handleDelete() {
      try {
        await destroyLetter(this.delete_letter_id);
        this.$store.commit("sendMessage", {
          text: this.$tc("model.letter_remove_success_message"),
        });
        this.fetchLetters();
      } catch (error) {
        if (error.response.status == 403) {
          this.$store.commit("sendMessage", {
            text: this.$tc("model.letter_not_authorized_delete_message"),
            color: "red",
          });
        } else {
          this.$store.commit("sendMessage", {
            text: this.$tc("model.letter_remove_error_message"),
            color: "red",
          });
        }
      } finally {
        this.delete_dialog = false;
        this.delete_letter_id = null;
      }
    },
    async fetchLetters() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.fetching = true;
      try {
        const response = await indexLetters({
          orderBy: sortBy[0] ? sortBy[0] : "",
          sortDesc: sortDesc[0] ? true : false,
          page,
          perPage: itemsPerPage,
          ...this.advanced_filter,
          lead_id: this.advanced_filter.lead?.id,
        });
        this.letters = response.data;
        this.totalItems = response.total;
      } finally {
        this.fetching = false;
      }
    },
    async fetchLeadsWithParticipantsNames(list_type, filter_lead_number) {
      this.leads = [];

      const already_has_lead_number = this[list_type].some((lead) =>
        lead.identifier_code.includes(filter_lead_number)
      );
      if (!already_has_lead_number) {
        this["fetching_" + list_type] = true;
        const response = await indexLeadsWithParticipantsNames(
          filter_lead_number
        );
        this[list_type] = response.data;
        this["fetching_" + list_type] = false;
      }
    },
    async initiallyLoadLeadsWithParticipantsNames() {
      this.fetching_leads_for_creation = true;
      this.fetching_leads_for_filtering = true;

      const response = await indexLeadsWithParticipantsNames();
      this.leads_for_creation = response.data;
      this.leads_for_filtering = response.data;

      this.fetching_leads_for_creation = false;
      this.fetching_leads_for_filtering = false;
    },
    generatedLeadLink(lead_number) {
      const route = this.$router.resolve({
        name: "lead_detail_files",
        params: { lead_number },
      });

      return route.href;
    },
    formatDate(date) {
      return formatDate(date);
    },

    // Advanced Filter
    handleAdvancedFilter() {
      this.fetchLetters();
      this.opened_advanced_filter = false;
    },
    handleResetFilter() {
      this.advanced_filter = {
        sent: "all",
        letter_type_id: null,
        recipient_id: null,
        lead_id: null,
        lead: {
          participants: [],
        },
      };
      this.filtered = false;
      this.fetchLetters();
    },
    async fetchAdvancedFilterContacts(filter = "") {
      if (this.advanced_search_fetchings_contacts) return;

      this.advanced_search_fetchings_contacts = true;
      const response = await this.$http.get("/contacts", {
        params: {
          filter,
        },
      });
      this.advanced_search_contacts_list = response.data.data;
      this.advanced_search_fetchings_contacts = false;
    },
    ...mapActions("lead_statuses", ["loadStatuses"]),
    ...mapActions("letter_types", ["loadLetterTypes"]),
  },
  computed: {
    letter_types() {
      return this.$store.getters["letter_types/getLetterTypes"];
    },
    lead_statuses() {
      return this.$store.getters["lead_statuses/getLeadStatuses"];
    },
    is_filtered() {
      return (
        this.advanced_filter.sent != "all" ||
        !!this.advanced_filter.letter_type_id ||
        !!this.advanced_filter.recipient_id ||
        !!this.advanced_filter.lead?.id
      );
    },
  },
  created() {
    this.fetchAdvancedFilterContacts();
    this.loadStatuses().finally(() => {
      this.initiallyLoadLeadsWithParticipantsNames();
    });
    this.loadLetterTypes();
    this.fetchLetters();
  },
  watch: {
    options: {
      handler() {
        this.fetchLetters();
      },
      deep: true,
    },
    filter: function (value) {
      if (value == null) this.fetchLetters();
    },
    search_lead_for_creation: _.debounce(async function (value) {
      if (value != null)
        this.fetchLeadsWithParticipantsNames("leads_for_creation", value);
    }, 500),
    search_lead_for_filtering: _.debounce(async function (value) {
      if (value != null)
        this.fetchLeadsWithParticipantsNames("leads_for_filtering", value);
    }, 500),
  },
};
</script>

<style></style>
